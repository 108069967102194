import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'assets/css/styles.css';
import { Toaster } from 'react-hot-toast';
import { ToastContainer } from 'react-toastify';
import { AiOutlineLoading3Quarters  } from "react-icons/ai";
// import Logo from 'assets/images/lemery-logo.png';

const Element = lazy(() => import('./pages/Elements'));
const UnderConstruction = lazy(() => import('./pages/UnderConstruction'));

// Auth Folder
const LoginPage = lazy(() => import('./pages/Auth/LoginPage'));
const RegisterPage = lazy(() => import('./pages/User/RegisterPage'));
const ForgotPasswordPage = lazy(() => import('./pages/Auth/ForgotPasswordPage'));

// Admin Folder
const AdminDashboardPage = lazy(() => import('./pages/Admin/DashboardPage'));
const AdminEmployeesPage = lazy(() => import('./pages/Admin/EmployeesPage'));
const AdminBranchesPage = lazy(() => import('./pages/Admin/BranchesPage'));
const AdminServicesPage = lazy(() => import('./pages/Admin/ServicesPage'));
const AdminInventoryPage = lazy(() => import('./pages/Admin/InventoryPage'));
const AdminTransactionPage = lazy(() => import('./pages/Admin/TransactionPage'));
const AdminFeedbacksPage = lazy(() => import('./pages/Admin/FeedbacksPage'));

// Employee Folder
const EmployeeDashboardPage = lazy(() => import('./pages/Employee/DashboardPage'));
const EmployeeTransactionPage = lazy(() => import('./pages/Employee/TransactionPage'));
const EmployeeInventoryPage = lazy(() => import('./pages/Employee/InventoryPage'));
const EmployeeCustomersPage = lazy(() => import('./pages/Employee/CustomerPage'));

// Customer Folder
const CustomerTransactionPage = lazy(() => import('./pages/Customer/TransactionPage'));
const CustomerPromoPage = lazy(() => import('./pages/Customer/PromoPage'));

// User Folder
const UserTransactionPage = lazy(() => import('./pages/User/TransactionPage'));
const UserServicesPage = lazy(() => import('./pages/User/ServicesOfferedPage'));
const UserBranchPage = lazy(() => import('./pages/User/BranchPage'));
const UserAboutUsPage = lazy(() => import('./pages/User/AboutUsPage'));
const UserCheckTransactionPage = lazy(() => import('./pages/User/CheckTransactionPage'));

const LandingPage = lazy(() => import('./pages/Shared/LandingPage'));

const App = () => {
    return (
        <Router>
            <Suspense fallback={
                <div className='global-loading text-dark d-flex flex-column align-items-center justify-content-center'>
                    <AiOutlineLoading3Quarters  size={40} className='loading mb-3' />
                    <span>Please wait...</span>
                </div>
            }>
                <Routes>
                    <Route path="/login" exact element={<LoginPage />} />
                    <Route path="/register" exact element={<RegisterPage />} />
                    <Route path="/forgot/password" exact element={<ForgotPasswordPage />} />

                    {/* <Route path="/element" exact element={<Element />} /> */}
                    
                    <Route path="/" exact element={<LandingPage />} />

                    {/* Admin Pages */}
                    <Route path="/admin/dashboard" exact element={<AdminDashboardPage />} />
                    <Route path="/admin/employees" exact element={<AdminEmployeesPage />} />
                    <Route path="/admin/branches" exact element={<AdminBranchesPage />} />
                    <Route path="/admin/services" exact element={<AdminServicesPage />} />
                    <Route path="/admin/inventory" exact element={<AdminInventoryPage />} />
                    <Route path="/admin/transactions" exact element={<AdminTransactionPage />} />
                    <Route path="/admin/feedbacks" exact element={<AdminFeedbacksPage />} />

                    {/* Employee Pages */}
                    <Route path="/employee/dashboard" exact element={<EmployeeDashboardPage />} />
                    <Route path="/employee/transaction" exact element={<EmployeeTransactionPage />} />
                    <Route path="/employee/inventory" exact element={<EmployeeInventoryPage />} />
                    <Route path="/employee/customers" exact element={<EmployeeCustomersPage />} />

                    {/* Customer Pages */}
                    <Route path="/user/transactions" exact element={<CustomerTransactionPage />} />
                    <Route path="/user/promo" exact element={<CustomerPromoPage />} />

                    {/* User Pages */}
                    <Route path="/check/transaction/:code" exact element={<UserTransactionPage />} />
                    <Route path="/branch/:id" exact element={<UserBranchPage />} />
                    <Route path="/services" exact element={<UserServicesPage />} />
                    <Route path="/transaction/checker" exact element={<UserCheckTransactionPage />} />
                    <Route path="/about-us" exact element={<UserAboutUsPage />} />
                    
                </Routes>
            </Suspense>
            <Toaster
              position="top-right"
              reverseOrder={false}
            /> {/* Declare the toast alert globally */}
            <ToastContainer />
        </Router>
    );
};

export default App;